import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import CareerForm from "../components/careerForm"

const Careers = ({ data }) => {
  const pageData = data?.allWpPage.nodes[0]
  const positions = data?.allWpCareer.nodes

  return (
    <Layout>
      <SEO title={pageData.seo.title} />
      <GlobalTop title={pageData.title} />
      <section className="careers-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                {parseTitle(
                  pageData.acfCareerPage.introContent.title,
                  pageData.acfCareerPage.introContent.titleHighlight
                )?.map((item, index) =>
                  item.highlighted ? (
                    <span key={index}>{item.text} </span>
                  ) : (
                    <Fragment key={index}>{item.text} </Fragment>
                  )
                )}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.acfCareerPage.introContent.description,
                }}
              />
            </div>
            <div className="col-md-6 text-center">
              <img
                className="img-fluid"
                src={pageData.acfCareerPage.introThumbnail.sourceUrl}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="careers-position">
        <h2 className="section--header">
          {pageData.acfCareerPage.positionTitle}
        </h2>
        <div className="row justify-content-center">
          {positions?.map((position, index) => (
            <div className="col-xl-3 col-sm-6" key={index}>
              <div className="careers-position__item">
                <div
                  className="careers-position__item-thumbnail"
                  style={{
                    backgroundImage: `url(${position.acfCareer.thumbnail.sourceUrl})`,
                  }}
                />
                <div className="careers-position__item-body">
                  <h3>{position.title}</h3>
                  <div>
                    <Link
                      to={`/kariera/${position.slug}`}
                      className="btn-custom btn-custom--bordered"
                    >
                      Więcej informacji
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <CareerForm positions={positions.map(position => position.title)} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDozNjIw" } }) {
      nodes {
        acfCareerPage {
          introContent {
            title
            titleHighlight
            description
          }
          introThumbnail {
            sourceUrl
          }
          positionTitle
        }
        seo {
          title
          metaDesc
        }
        title
        id
      }
    }

    allWpCareer(sort: { fields: date }) {
      nodes {
        acfCareer {
          content {
            title
            description
          }
          thumbnail {
            sourceUrl
          }
        }
        title
        slug
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

const parseTitle = (string, highlight) => {
  if (!string) return []

  const initial = [
    {
      text: string,
      highlighted: false,
    },
  ]

  if (!highlight) return initial

  const hlIndex = string.indexOf(highlight)
  const hlLength = highlight.length

  if (hlIndex < 0) return initial

  let newString = []

  if (hlIndex === 0) {
    const strFirst = string.slice(0, hlLength)
    const strSecond = string.slice(hlLength + 1, string.length)

    newString = [
      {
        text: strFirst,
        highlighted: true,
      },
      {
        text: strSecond,
        highlighted: false,
      },
    ]
  } else if (hlIndex + hlLength === string.length) {
    const strFirst = string.slice(0, hlIndex - 1)
    const strSecond = string.slice(hlIndex, string.length)

    newString = [
      {
        text: strFirst,
        highlighted: false,
      },
      {
        text: strSecond,
        highlighted: true,
      },
    ]
  } else {
    const strFirst = string.slice(0, hlIndex - 1)
    const strSecond = string.slice(hlIndex, hlIndex + hlLength)
    const strThird = string.slice(hlIndex + hlLength + 1, string.length)

    newString = [
      {
        text: strFirst,
        highlighted: false,
      },
      {
        text: strSecond,
        highlighted: true,
      },
      {
        text: strThird,
        highlighted: false,
      },
    ]
  }

  return newString
}

export default Careers
